import Link from "next/link";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";

type TopBarIconProps = {
  iconName: React.ComponentProps<typeof SVGAtom>["iconName"];
  href?: string;
  onClick?: () => void;
};

type Props = {
  title: string;
  topBarIconProps: TopBarIconProps[];
};

export const TitleTopBar = (props: Props) => {
  return (
    <div className="relative flex h-[54px] w-full flex-shrink-0 flex-col items-start justify-center gap-[10px] p-[14px_20px]">
      <p className="font-airbnb text-[22px] font-black leading-[20px] text-[#092B00]">
        {props.title}
      </p>
      {props.topBarIconProps?.map((topBarIconProp, index) => {
        return (
          <Link
            scroll={false}
            key={index}
            className={`absolute bottom-[4.615px] z-10 flex items-center justify-center p-[12px]`}
            style={{ right: `${index * 44 + 4}px` }}
            href={topBarIconProp.href ?? ""}
            onClick={topBarIconProp.onClick}
          >
            <SVGAtom
              iconName={topBarIconProp.iconName}
              width={20}
              height={20}
              color="black"
            />
          </Link>
        );
      })}
    </div>
  );
};
