type Props = {
  isBSOpen: boolean;
  closeBottomSheet: () => void;
};

export const BottomSheet = (props: React.PropsWithChildren<Props>) => {
  return (
    <>
      <div>
        <div
          className={`fixed bottom-0 z-[1001] w-full rounded-t-[20px] bg-white pb-[40px] pt-[20px] text-[#000] transition-transform duration-300 desktop:w-[400px] ${
            props.isBSOpen ? "translate-y-0" : "translate-y-full"
          }`}
        >
          <div className="flex flex-col gap-0">{props.children}</div>
        </div>
        <div
          className={`fixed inset-0 z-[1000] overflow-hidden bg-[#00000040] transition-opacity duration-300 ${
            props.isBSOpen ? "opacity-100" : "pointer-events-none opacity-0"
          }`}
          onClick={() => {
            if (props.isBSOpen) {
              props.closeBottomSheet();
            }
          }}
        ></div>
      </div>
    </>
  );
};
