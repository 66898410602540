import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { BackButton } from "~/components/Components/BackButton/BackButton";

type Props = {
  titleText: string;
  icon?: React.ComponentProps<typeof SVGAtom>;
  iconText: string;
  onIconClick: () => void;
  rightIcon?: React.ComponentProps<typeof SVGAtom>;
};

export const IconTopBar = (props: Props) => {
  return (
    <>
      <div className="absolute left-[7px] top-[7px] z-10">
        <BackButton />
      </div>
      <div className="relative flex h-[54px] w-full flex-col items-start justify-center p-[0px_50px]">
        <div className="flex w-full flex-wrap content-center items-center gap-[8px]">
          <p className="overflow-hidden truncate text-[17px] font-bold leading-[24px] text-[rgba(0,0,0,0.85)]">
            {props.titleText}
          </p>
          {props.icon && (
            <div
              className="flex cursor-pointer items-center justify-center gap-[3px] rounded-[4px] bg-blue px-[8px]"
              onClick={props.onIconClick}
            >
              <SVGAtom {...props.icon} />
              <p className="text-center text-[14px] font-light leading-[22px] text-[#FFF]">
                {props.iconText}
              </p>
            </div>
          )}
        </div>
      </div>
      {props.rightIcon && (
        <div className="absolute right-[8px] top-[5px] z-10 flex cursor-pointer items-start p-[12px]">
          <SVGAtom {...props.rightIcon} />
        </div>
      )}
    </>
  );
};
