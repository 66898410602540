import { useRouter } from "next/navigation";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { BackButton } from "~/components/Components/BackButton/BackButton";

type Props = {
  titleText: string;
  rightText: string;
  iconName?: React.ComponentProps<typeof SVGAtom>["iconName"];
  onLeftIconClick?: () => void;
  onRightTextClick: () => void;
};

export const TextTopBar = (props: Props) => {
  const router = useRouter();

  return (
    <>
      <div className="absolute left-[7px] top-[7px] z-10">
        {props.iconName ? (
          <div className="cursor-pointer p-[15px]">
            <SVGAtom
              iconName={props.iconName}
              width={20}
              height={20}
              color="black"
              onClick={() => {
                if (props.onLeftIconClick) {
                  props.onLeftIconClick();
                } else {
                  router.back();
                }
              }}
            />
          </div>
        ) : (
          <BackButton />
        )}
      </div>
      <div className="relative flex h-[54px] w-full flex-col items-start justify-center p-[0px_50px]">
        <div className="flex flex-wrap content-center items-center gap-[8px]">
          <p className="align-stretch block overflow-hidden truncate text-ellipsis text-center font-[Inter] text-[17px] font-bold leading-[24px] text-[rgba(0,0,0,0.85)]">
            {props.titleText}
          </p>
        </div>
      </div>
      <div
        className="absolute right-[8px] top-[6px] z-10 flex cursor-pointer items-start p-[12px]"
        onClick={props.onRightTextClick}
      >
        <p className="cursor-pointer overflow-hidden truncate font-[Inter] text-[15px] font-medium leading-normal text-[#004BFF]">
          {props.rightText}
        </p>
      </div>
    </>
  );
};
