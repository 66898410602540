"use client";

import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import "~/styles/globals.css";
import { useRecoilState } from "recoil";
import {
  pointBalanceAtom,
  refreshBalanceCountAtom,
  searchTextAtom,
} from "~/state/atoms";
import { api } from "~/trpc/react";

export const useSearch = (type: "HOME" | "VOTE") => {
  const { data: session } = useSession();
  const [searchText, setSearchText] = useRecoilState(searchTextAtom);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [serchResultCount, setSerchResultCount] = useState<
    number | undefined
  >();

  // const apiGetTotalCount =
  //   type === "HOME"
  //     ? api.feed.getTotalCount.useQuery(
  //         { searchText },
  //         { enabled: !!searchText },
  //       )
  //     : api.vote.getTotalCount.useQuery(
  //         { searchText },
  //         { enabled: !!searchText },
  //       );

  // useEffect(() => {
  //   setSerchResultCount(
  //     isSearchMode && searchText ? apiGetTotalCount.data : undefined,
  //   );
  // }, [apiGetTotalCount.data, isSearchMode, searchText]);

  return {
    searchText,
    setSearchText,
    isSearchMode,
    serchResultCount,
    setSerchResultCount,
    setIsSearchMode,
  };
};
