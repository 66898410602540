import { type Fandom } from "@prisma/client";
import { Image, List, Row } from "antd";
import Link from "next/link";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { useSearch } from "~/hooks/useSearch";
import { api } from "~/trpc/react";

type Props = {
  //
};

export const HomeSearchedFandomCardListModule = (props: Props) => {
  const { searchText, setSearchText } = useSearch("HOME");
  const [fandoms, setFandoms] = useState<Fandom[]>([]);

  const apiGetFandoms = api.fandom.getSearch.useQuery(
    { searchText },
    { enabled: !!searchText },
  );

  useEffect(() => {
    if (!searchText) {
      setFandoms([]);
      return;
    }

    if (apiGetFandoms.data) {
      setFandoms(apiGetFandoms.data);
    }
  }, [apiGetFandoms.data, searchText]);

  return !fandoms.length ? null : (
    <List className="!my-[10px] bg-white !py-[10px]">
      {fandoms.map((fandom) => (
        <Link key={fandom.id} href={`/fandom/${fandom.id}`}>
          <div className="flex w-full items-center gap-[20px] px-[20px] py-[10px]">
            <Image
              width={50}
              height={50}
              src={fandom.imageUrl}
              className="rounded-full"
              alt={`fandom Image of ${fandom.title}`}
              preview={false}
            />
            <span
              className="truncate text-[17px] leading-[26px] text-[rgba(0,0,0,0.85)]"
              style={{ maxWidth: "calc(100% - 70px)" }}
            >
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={fandom.title}
              />
            </span>
          </div>
        </Link>
      ))}
    </List>
  );
};
