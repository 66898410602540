import {
  type User,
  type Feed as FeedModel,
  type Fandom,
  type File,
} from "@prisma/client";
import timeAgo from "~/utils/timeAgo";
import { List, Select, Spin } from "antd";
import { FeedCard } from "~/components/Components/FeedCard/FeedCard";
import { CenterSpinnerAtom } from "~/components/Atoms/CenterSpinnerAtom/CenterSpinnerAtom";
import {
  VoteCard,
  type VoteDetail,
} from "~/components/Components/VoteCard/VoteCard";
import Image from "next/image";
import "./custom.css";
import dayjs from "dayjs";
import { useSearch } from "~/hooks/useSearch";
import { Option } from "antd/es/mentions";
import { useRouter } from "next/navigation";

export type FeedHome = FeedModel & {
  User: Partial<User>;
  Fandom: Partial<Fandom>;
  Vote: VoteDetail;
  Files: File[];
};

type Props = {
  feeds: FeedHome[];
  isLoading: boolean;
  hasMoreData: boolean;
  updateLike: (feedId: number, isLiked: boolean) => void;
};

export const HomeFeedCardListModule = (props: Props) => {
  const router = useRouter();

  return (
    <>
      {props.isLoading ? (
        <CenterSpinnerAtom type="MORE" />
      ) : (
        <>
          <List
            locale={{
              emptyText: (
                <div className="flex h-[calc(100vh-120px)] items-center justify-center bg-white p-0">
                  <div className="inline-flex flex-col items-center gap-[16px]">
                    <Image
                      src={"/assets/homeEmpty.png"}
                      width={100}
                      height={100}
                      alt="homeEmpty"
                    />
                    <p className="text-[13px] leading-[20px] text-[#000]">
                      {"No Feed found"}
                    </p>
                  </div>
                </div>
              ),
            }}
            style={{ backgroundColor: "#f5f5f5" }}
            itemLayout="vertical"
            dataSource={props.feeds}
            renderItem={(feed) => (
              <List.Item
                style={{
                  padding: "0px",
                  border: "none",
                  backgroundColor: "white",
                }}
                key={feed.id}
              >
                {feed.Vote ? (
                  <VoteCard vote={feed.Vote} type={"LIST"} />
                ) : (
                  <FeedCard
                    feedFileThumbnailProps={{ files: feed.Files ?? [] }}
                    feedProfileProps={{
                      image: feed.User?.imageUrl ?? "/assets/avatar.jpeg",
                      userName: feed.User?.nickname ?? "undefined",
                      fandomName: feed.Fandom?.title ?? "undefined",
                      feedDate: timeAgo.format(feed.createdAt),
                    }}
                    feedContentProps={{
                      href: `/feed/${feed.id}`,
                      title: feed.title,
                      content: feed.content,
                      isNew:
                        feed.createdAt >
                        new Date(Date.now() - 24 * 60 * 60 * 1000),
                      isPopular: feed.score > dayjs().unix() / 3600 + 10,
                    }}
                    feedActionBoxProps={{
                      actionFeedLikeProps: {
                        feedId: feed.id,
                        likeCount: feed.totalLikeCount,
                        updateLike: props.updateLike,
                      },
                      actionFeedCommentProps: {
                        feedId: feed.id,
                        totalCommentCount: feed.totalCommentCount,
                      },
                      shareData: {
                        shareUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/share/feed/${feed.id}`,
                        feedId: feed.id,
                      },
                    }}
                  />
                )}
              </List.Item>
            )}
          />
          {props.hasMoreData ? (
            <CenterSpinnerAtom type="MORE" />
          ) : (
            <div className="h-[100px] bg-[#f5f5f5]"></div>
          )}
        </>
      )}
    </>
  );
};
