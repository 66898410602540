import { CloseCircleOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import LogoAtom from "~/components/Atoms/LogoAtom/LogoAtom";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";

type Props = {
  serchResultCount?: number;
  onSearch: (value: string) => void;
  onCloseClicked: () => void;
};

export const SearchTopBar = (props: Props) => {
  return (
    <>
      <div className="flex h-[54px] w-full flex-col">
        <div className="flex h-[54px] items-center justify-center gap-[10px] pl-[20px] pr-[60px] ">
          <Input
            placeholder="input search text"
            allowClear
            size="large"
            prefix={<SVGAtom iconName="search" width={14} height={14} />}
            // onSearch={props.onSearch}
            onPressEnter={(e) => {
              props.onSearch(e.currentTarget.value);
            }}
            autoFocus
          />
          <div className="absolute right-[8px] top-[5px] flex h-[44px] w-[44px] items-center justify-center p-[12px]">
            <SVGAtom
              iconName="close"
              width={20}
              height={20}
              onClick={() => {
                props.onCloseClicked();
              }}
            />
          </div>
        </div>
      </div>
      {props.serchResultCount !== undefined && (
        <div className="fixed top-[54px] h-[24px] w-full bg-white pl-[10px] desktop:max-w-[400px]">
          {props.serchResultCount} results
        </div>
      )}
    </>
  );
};
