import { useRouter } from "next/navigation";
import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { useAuth } from "~/hooks/useAuth";

export const BackButton = ({ runLogout }: { runLogout?: boolean }) => {
  const router = useRouter();
  const { logout } = useAuth();

  return (
    <div
      className="flex cursor-pointer items-start gap-[10px] p-[12px]"
      onClick={() => (runLogout ? logout() : router.back())}
    >
      <SVGAtom iconName="leftArrow" width={20} height={20} color="black" />
    </div>
  );
};
