import { useSession } from "next-auth/react";
import { HomeTemplate } from "~/components/Templates/Home/HomeTemplate";
import { useBottomSheet } from "~/hooks/useBottomSheet";
import { useTimelineMode } from "~/hooks/useTimelineMode";
import { useInfiniteScroll } from "~/hooks/useInfiniteScroll";
import { useSearch } from "~/hooks/useSearch";
import { api } from "~/trpc/react";
import { TimelineMode } from "~/utils/constants";
import { message } from "antd";

export const HomeContainer = () => {
  const { timelineMode, setTimelineMode } = useTimelineMode();

  const {
    searchText,
    setSearchText,
    isSearchMode,
    serchResultCount,
    setSerchResultCount,
    setIsSearchMode,
  } = useSearch("HOME");

  const {
    datas: feeds,
    hasMoreData,
    isLoading,
    resetDatas,
  } = useInfiniteScroll({
    dataType: "FEED",
    take: 10,
    searchText,
    timelineMode,
  });

  const { isBSOpen, openBottomSheet, closeBottomSheet } = useBottomSheet();

  const { data: session, update } = useSession();

  const apiLikeFeed = api.feed.likeFeed.useMutation({
    onSuccess: (data) => {
      if (data === "get 1 FAO for Like the feed") {
        void message.success("You received 1 FAO for Like the feed");
      }
    },
  });

  const updateLike = (feedId: number, isLiked: boolean, userId?: string) => {
    if (userId) {
      apiLikeFeed.mutate({
        feedId,
        userId,
        isLiked,
      });
    }
  };

  const onChangeTimelineMode = (timelineMode: TimelineMode) => {
    resetDatas();
    setTimelineMode(timelineMode);
    closeBottomSheet();
  };

  const onSearch = (searchText: string) => {
    resetDatas();
    void setSearchText(searchText);
  };

  const homeTemplateProps: React.ComponentProps<typeof HomeTemplate> = {
    homeTopBarProps: {
      isSearchMode,
      searchTopBarProps: {
        serchResultCount,
        onSearch: onSearch,
        onCloseClicked: () => {
          resetDatas();
          setSerchResultCount(undefined);
          setSearchText("");
          setIsSearchMode(false);
        },
      },
      logoTopBarProps: {
        topBarIconProps: [
          {
            iconName: "history",
            href: "/pointHistory",
          },
          {
            iconName: "search",
            onClick: () => {
              setIsSearchMode(true);
            },
          },
        ],
      },
    },
    timelineModeButtonModuleProps: {
      title: "HometimelineButtonModule",
      timelineButtonProps: {
        timelineMode,
        onButtonClick: () => {
          openBottomSheet();
        },
      },
      timelineModeBottomSheetProps: {
        bottomSheetProps: {
          isBSOpen,
          closeBottomSheet,
        },
        bottomSheetContent: [
          {
            iconProps: {
              iconName: "fire",
              width: 12,
              height: 12,
            },
            text: "Hot",
            onClick: () => {
              onChangeTimelineMode(TimelineMode.HOT);
            },
          },
          {
            iconProps: {
              iconName: "new",
              width: 12,
              height: 12,
            },
            text: "New",
            onClick: () => {
              onChangeTimelineMode(TimelineMode.NEW);
            },
          },
        ],
      },
    },
    homeFeedModuleProps: {
      feeds,
      isLoading,
      hasMoreData,
      updateLike: (feedId: number, isLiked: boolean) =>
        updateLike(feedId, isLiked, session?.user?.id),
    },
  };

  return <HomeTemplate {...homeTemplateProps} />;
};
