import { type LogoTopBar } from "~/components/Components/LogoTopBar/LogoTopBar";
import { type SearchTopBar } from "~/components/Components/SearchTopBar/SearchTopBar";
import { TopBar } from "~/components/Components/TopBar/TopBar";

type Props = {
  isSearchMode: boolean;
  logoTopBarProps: React.ComponentProps<typeof LogoTopBar>;
  searchTopBarProps: React.ComponentProps<typeof SearchTopBar>;
};

export const HomeTopBarModule = (props: Props) => {
  return (
    <>
      {props.isSearchMode ? (
        <TopBar barType="search" topBarProps={props.searchTopBarProps} />
      ) : (
        <TopBar barType="logo" topBarProps={props.logoTopBarProps} />
      )}
    </>
  );
};
