import SVGAtom from "~/components/Atoms/SVGAtom/SVGAtom";
import { BackButton } from "~/components/Components/BackButton/BackButton";
import cn from "classnames";

type Props = {
  titleText: string;
  textClassName?: string;
  leftIcon?: React.ComponentProps<typeof SVGAtom>;
  rightIcon?: React.ComponentProps<typeof SVGAtom>;
  runLogout?: boolean;
};

export const TitleCenterTopBar = (props: Props) => {
  return (
    <div className="w-full">
      <div className="absolute left-[7px] top-[7px] z-10 cursor-pointer">
        {props.leftIcon ? (
          <div className="flex items-start gap-[10px] p-[12px]">
            <SVGAtom {...props.leftIcon} />
          </div>
        ) : (
          <BackButton runLogout={props.runLogout} />
        )}
      </div>
      <div className="relative flex h-[54px] flex-col items-start justify-center p-[0px_50px]">
        <div
          className={cn(
            props.textClassName,
            "w-full overflow-hidden text-ellipsis whitespace-nowrap text-center text-[17px] font-bold leading-[24px]",
          )}
        >
          {props.titleText}
        </div>
      </div>
      {props.rightIcon && (
        <div className="absolute right-[8px] top-[5px] z-10 flex cursor-pointer items-start p-[12px]">
          <SVGAtom {...props.rightIcon} />
        </div>
      )}
    </div>
  );
};
