import { useEffect, useState } from "react";
import { useDisableScroll } from "./useDisableScroll";

export const useBottomSheet = () => {
  const [isBSOpen, setIsBSOpen] = useState<boolean>(false);
  const { disableScroll, enableScroll } = useDisableScroll();

  useEffect(() => {
    if (isBSOpen) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [isBSOpen]);

  const openBottomSheet = () => {
    setIsBSOpen(true);
  };

  const closeBottomSheet = () => {
    setIsBSOpen(false);
  };

  return { isBSOpen, openBottomSheet, closeBottomSheet };
};
