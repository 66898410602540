import { type HomeBanner } from "@prisma/client";
import { Carousel } from "antd";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { showLoaderOverlayAtom } from "~/state/atoms";
import { api } from "~/trpc/react";

type Props = {
  //
};

export const HomeBannerModule = (props: Props) => {
  const router = useRouter();
  const [banners, setBanners] = useState<HomeBanner[]>([]);
  const apiGetHomeBanners = api.common.getHomeBanners.useQuery();
  const setShowLoaderOverlay = useSetRecoilState(showLoaderOverlayAtom);

  useEffect(() => {
    if (apiGetHomeBanners.data) {
      setBanners(apiGetHomeBanners.data);
    }
  }, [apiGetHomeBanners.data]);

  return (
    <Carousel autoplay>
      {banners.map((banner) => (
        <div
          key={banner.id}
          className="aspect-5/2 relative w-full cursor-pointer"
          onClick={() => {
            if (!banner.linkUrl) {
              return;
            }

            if (banner.linkUrl.startsWith("http")) {
              window.open(banner.linkUrl, "_blank");
              return;
            }

            setShowLoaderOverlay(true);
            router.push(banner.linkUrl);
          }}
        >
          <Image src={banner.imageUrl} objectFit="cover" fill alt="" />
        </div>
      ))}
    </Carousel>
  );
};
