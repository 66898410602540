import { useCallback, useEffect } from "react";

export const useDisableScroll = () => {
  useEffect(() => {
    return () => enableScroll();
  }, []);

  const preventScroll = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const disableScroll = useCallback(() => {
    window.addEventListener("wheel", preventScroll, { passive: false });
    window.addEventListener("touchmove", preventScroll, { passive: false });
  }, [preventScroll]);

  const enableScroll = useCallback(() => {
    window.removeEventListener("wheel", preventScroll);
    window.removeEventListener("touchmove", preventScroll);
  }, [preventScroll]);

  return { disableScroll, enableScroll, preventScroll };
};
