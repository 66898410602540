import React from "react";
import { IconTopBar } from "~/components/Components/IconTopBar/IconTopBar";
import { LogoTopBar } from "~/components/Components/LogoTopBar/LogoTopBar";
import { TextTopBar } from "~/components/Components/TextTopBar/TextTopBar";
import { TitleCenterTopBar } from "~/components/Components/TitleCenterTopBar/TitleCenterTopBar";
import { TitleTopBar } from "~/components/Components/TitleTopBar/TitleTopBar";
import { SearchTopBar } from "../SearchTopBar/SearchTopBar";

type baseTopBarProps = {
  isBackgroundClean?: boolean;
  isBorder?: boolean;
};

type IconBarProps = baseTopBarProps & {
  barType: "icon";
  topBarProps: React.ComponentProps<typeof IconTopBar>;
};

type TextBarProps = baseTopBarProps & {
  barType: "text";
  topBarProps: React.ComponentProps<typeof TextTopBar>;
};

type TitleCenterBarProps = baseTopBarProps & {
  barType: "titleCenter";
  topBarProps: React.ComponentProps<typeof TitleCenterTopBar>;
};

type TitleBarProps = baseTopBarProps & {
  barType: "title";
  topBarProps: React.ComponentProps<typeof TitleTopBar>;
};

type LogoBarProps = baseTopBarProps & {
  barType: "logo";
  topBarProps: React.ComponentProps<typeof LogoTopBar>;
};

type SearchBarProps = baseTopBarProps & {
  barType: "search";
  topBarProps: React.ComponentProps<typeof SearchTopBar>;
};

type Props =
  | IconBarProps
  | TitleCenterBarProps
  | TextBarProps
  | LogoBarProps
  | SearchBarProps
  | TitleBarProps;

export const TopBar: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={`fixed top-0 z-[100] flex h-[54px] w-full flex-col items-start justify-center ${
        props.isBackgroundClean ? "" : "bg-white"
      } ${
        props.isBorder ? "border-b-[1px] border-[rgba(0,0,0,0.06)]" : ""
      } desktop:w-[400px]`}
    >
      {props.barType === "icon" && <IconTopBar {...props.topBarProps} />}

      {props.barType === "text" && <TextTopBar {...props.topBarProps} />}

      {props.barType === "titleCenter" && (
        <TitleCenterTopBar {...props.topBarProps} />
      )}

      {props.barType === "logo" && <LogoTopBar {...props.topBarProps} />}

      {props.barType === "title" && <TitleTopBar {...props.topBarProps} />}

      {props.barType === "search" && <SearchTopBar {...props.topBarProps} />}
    </div>
  );
};
