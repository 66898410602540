import { HomeFeedCardListModule } from "~/components/Modules/Home/FeedCardListModule";
import { HomeTopBarModule } from "~/components/Modules/Home/HomeTopBarModule";
import { TimelineModeBottomSheetModule } from "~/components/Modules/Common/TimelineModeBottomSheetModule";
import { HomeSearchedFandomCardListModule } from "~/components/Modules/Home/HomeSearchedFandomCardListModule";
import { TimelineModeButtonModule } from "~/components/Modules/Common/TimelineModeButtonModule";
import { HomeBannerModule } from "~/components/Modules/Home/HomeBannerModule";
import { useRecoilValue } from "recoil";
import { searchTextAtom } from "~/state/atoms";

type Props = {
  homeTopBarProps: React.ComponentProps<typeof HomeTopBarModule>;
  timelineModeButtonModuleProps: React.ComponentProps<
    typeof TimelineModeButtonModule
  >;
  homeFeedModuleProps: React.ComponentProps<typeof HomeFeedCardListModule>;
};

export const HomeTemplate = (props: Props) => {
  const searchText = useRecoilValue(searchTextAtom);
  return (
    <div className="h-full">
      <HomeTopBarModule {...props.homeTopBarProps} />
      {props.homeTopBarProps.isSearchMode && searchText !== "" ? undefined : (
        <HomeBannerModule />
      )}
      <HomeSearchedFandomCardListModule />
      <TimelineModeButtonModule {...props.timelineModeButtonModuleProps} />
      <HomeFeedCardListModule {...props.homeFeedModuleProps} />
    </div>
  );
};
